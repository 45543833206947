<template>
  <ais-panel class="ais-Panel-collapsible" :class="{ 'ais-Panel-collapsed': collapsed }">
    <template #header>
      <div class="ais-Panel-header-container" :aria-expanded="collapsed" @click="toggle">
        <span>{{ label }}</span>
        <icon :name="`${collapsed ? 'pt-plus' : 'pt-minus'}`" />
      </div>
    </template>
    <template #default>
      <slot />
    </template>
  </ais-panel>
</template>
<script>
export default {
  name: 'AisCustomPanel',
  props: {
    label: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const collapsed = ref(props.collapse);

    const toggle = () => {
      collapsed.value = !collapsed.value;
    };

    return {
      collapsed,
      toggle
    };
  }
};
</script>
