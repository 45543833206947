<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <Section v-if="startup?.header" grid="E" :bg-hero="startup.header.media" padding-y="py-10">
      <template #header>
        <div class="container w-full flex align-baseline justify-between mb-12">
          <Breadcrumbs path="startups" :section="$t('breadcrumbs.startups')" color="white" />
        </div>
      </template>
      <template #content>
        <div class="max-w-[615px]">
          <h1
            class="mb-5 mt-8 md:-mt-5 text-3xl sm:text-[40px] font-ubuntu font-bold lg:leading-[50px] text-white"
            v-text="startup.header.title"
          />
          <Markdown
            class="max-w-[588px] mb-8 text-base sm:text-lg text-white"
            :content="startup.header.description"
          />
          <div class="flex flex-wrap items-center justify-center sm:justify-start -m-2.5">
            <NuxtLink
              v-if="startup?.call_for_proposal_active"
              class="btn btn_blue text-sm m-2.5 justify-center"
              :to="`${STARTUPS_BASE_UR}/${startup?.call_for_proposal_active?.slug}`"
            >
              {{ startup?.call_for_proposal_active?.name }}
            </NuxtLink>
            <NuxtLink
              v-if="startup?.header?.link"
              class="btn text-sm m-2.5 justify-center"
              :class="startup?.header?.link?.type"
              :to="startup?.header?.link?.url"
            >
              {{ startup?.header?.link?.label }}
            </NuxtLink>
          </div>
        </div>
      </template>
    </Section>
    <Section v-if="startup?.content" grid="E" padding-y="py-0" class="mb-14">
      <template #content>
        <markdown :content="startup?.content" />
      </template>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Sections';
import Breadcrumbs from '@/components/Breadcrumbs';
import Markdown from '@/components/Markdown';
import { STARTUPS_BASE_UR } from '@/utils/enums';

export default {
  name: 'PtStartups',
  components: {
    Section,
    Breadcrumbs,
    Markdown
  },

  async setup() {
    const { $store } = useNuxtApp();
    const { createHeadSeo } = useHeadSeo();
    const { data } = await useAsyncData('startups', async () => {
      const startup = await $store.dispatch('strapi/getStartups');
      return { startup };
    });
    const startup = computed(() => data.value?.startup);
    watch(
      startup,
      val => {
        const {
          meta: {
            title,
            description,
            og_image: { url }
          }
        } = val;
        createHeadSeo(title, description, url);
      },
      { immediate: true, deep: true }
    );

    return {
      startup,
      STARTUPS_BASE_UR
    };
  }
};
</script>
