<template>
  <div>
    <div class="ais-ClearRefinements">
      <button
        class="btn"
        :class="
          hasSelectedFilters || (refCrossFacets ? filter && filter.length > 0 : false)
            ? 'btn__outline--blue'
            : 'btn__outline--gray disabled:opacity-50'
        "
        :disabled="!hasSelectedFilters && (refCrossFacets ? !filter || filter.length === 0 : true)"
        type="button"
        @click.prevent="clearRefinements"
      >
        {{ $t('ui.button.clear') }}
      </button>
    </div>
    <div v-if="facets" class="flex flex-col divide-y divide-gray-soft">
      <ais-custom-panel
        v-for="(item, index) in facets"
        :key="index"
        :label="item.name"
        :collapse="item.collapse"
      >
        <ais-refinement-list
          :attribute="item.attribute"
          show-more
          :sort-by="
            sortingFunc ||
            (sortingFacets === 'alpha'
              ? ['isRefined', 'name:desc']
              : sortingFacets === 'name'
                ? ['name']
                : undefined)
          "
          :show-more-limit="100"
        >
          <template #default="{ items, isShowingMore, canToggleShowMore, toggleShowMore }">
            <ul v-if="infogramList">
              <template v-for="facet in items" :key="facet.value">
                <li v-if="facet.count" v-appear>
                  <label>
                    <input
                      type="checkbox"
                      :checked="
                        (selectedFacets &&
                          selectedFacets[item.attribute] &&
                          selectedFacets[item.attribute].includes(facet.value)) ||
                        (refCrossFacets
                          ? typeCrossFacets(item.attribute) &&
                            filter.includes(facet.value.toLowerCase())
                          : false)
                      "
                      @[ev]="removeCrossFacets(facet.value, item.attribute)"
                      @change="
                        changeFacetCheckbox(facet.value, item.attribute, $event.target.checked)
                      "
                    />
                    <span class="ais-RefinementList-name">
                      {{
                        item.transform
                          ? firstLetterUppercase($t(`strapiDataMap.${facet.label}`))
                          : facet.label
                      }}
                    </span>
                    <span class="ais-RefinementList-count">
                      {{ facet.count.toLocaleString() }}
                    </span>
                  </label>
                </li>
              </template>
            </ul>
            <ul v-else>
              <li v-for="facet in items" :key="facet.value">
                <label>
                  <input
                    type="checkbox"
                    :checked="
                      (selectedFacets &&
                        selectedFacets[item.attribute] &&
                        selectedFacets[item.attribute].includes(facet.value)) ||
                      (refCrossFacets
                        ? getCrossFacet(item.attribute, facet.value.toLowerCase())
                        : false)
                    "
                    @[ev]="removeCrossFacets(facet.value, item.attribute)"
                    @change="
                      changeFacetCheckbox(facet.value, item.attribute, $event.target.checked)
                    "
                  />
                  <span class="ais-RefinementList-name">
                    {{
                      item.transform
                        ? firstLetterUppercase($t(`strapiDataMap.${facet.label}`))
                        : facet.label
                    }}
                  </span>
                  <span class="ais-RefinementList-count"> {{ facet.count.toLocaleString() }} </span>
                </label>
              </li>
            </ul>
            <button
              v-if="canToggleShowMore"
              class="mt-5 link"
              type="button"
              @click="toggleShowMore"
            >
              {{ !isShowingMore ? $t('ui.label.seeMore') : $t('ui.label.seeLess') }}
              <icon
                :name="!isShowingMore ? 'pt-angle-down' : 'pt-angle-up'"
                class="text-xl ml-0 text-blue"
              />
            </button>
          </template>
        </ais-refinement-list>
      </ais-custom-panel>
    </div>
  </div>
</template>
<script>
import { firstLetterUppercase } from '@/utils/helpers/string';
import AisCustomPanel from '@/components/Instantsearch/Panel';

export default {
  name: 'AisCustomRefinementList',
  components: {
    AisCustomPanel
  },
  props: {
    facets: {
      type: Array,
      default: () => []
    },
    crossFacets: {
      type: [Boolean, String, Array],
      default: false
    },
    sortingFacets: {
      type: String,
      default: 'quantity',
      validator: val => /quantity|name|alpha/.test(val)
    },
    sortingFunc: {
      type: Function,
      default: null
    },
    scrollSectionId: {
      type: String,
      default: ''
    },
    infogramList: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  data() {
    return {
      firstLetterUppercase,
      selectedFacets: null
    };
  },
  computed: {
    hasSelectedFilters() {
      return this.selectedFacets !== null;
    },
    filter() {
      return this.$store.getters['search/getByField']('filter');
    },
    refCrossFacets() {
      if (Array.isArray(this.crossFacets)) {
        return this.crossFacets.length > 0;
      } else {
        return this.crossFacets;
      }
    },
    ev() {
      return this.refCrossFacets ? 'click' : null;
    }
  },
  watch: {
    filter(val) {
      this.$emit(
        'change',
        val && this.refCrossFacets ? [[`${this.refCrossFacets}='${val[0]}'`]] : null
      );
    }
  },
  methods: {
    changeFacetCheckbox(facetValue, attribute, checked) {
      (this.selectedFacets ??= {})[attribute] ??= [];
      if (checked || (this.filter && this.filter[0] === facetValue.toLowerCase())) {
        this.selectedFacets[attribute].push(facetValue);
      } else {
        this.selectedFacets[attribute] = this.selectedFacets[attribute].filter(
          element => element !== facetValue
        );
        if (this.selectedFacets[attribute].length === 0) delete this.selectedFacets[attribute];
        if (Object.keys(this.selectedFacets).length === 0) this.selectedFacets = null;
      }
      const filters = [];
      if (this.selectedFacets) {
        const createFacetFilters = (filterName, filters) => {
          let result = [];
          if (typeof filters === 'string') {
            result = [`${filterName}='${filters}'`];
          } else if (Array.isArray(filters)) {
            const customFilters = [];
            filters.forEach(facet => {
              customFilters.push(`${filterName}='${facet}'`);
            });
            result.push(customFilters);
          }
          return result;
        };
        Object.keys(this.selectedFacets).forEach(facet => {
          filters.push(createFacetFilters(facet, this.selectedFacets[facet])[0]);
        });
      }
      this.scrollToSectionId();
      this.$emit('change', filters);
    },
    typeCrossFacets(attribute) {
      return typeof this.crossFacets === 'boolean'
        ? this.crossFacets
        : this.crossFacets.includes(attribute);
    },
    removeCrossFacets(facet, attribute) {
      if (this.typeCrossFacets(attribute) && this.filter.includes(facet.toLowerCase())) {
        this.$store.commit('search/setFilter', '');
      }
    },
    getCrossFacet(attribute, facet) {
      const crossCheck = this.typeCrossFacets(attribute) && this.filter.includes(facet);
      return crossCheck;
    },
    clearRefinements() {
      this.selectedFacets = null;
      this.$store.commit('search/setFilter', '');
      this.scrollToSectionId();
      this.$emit('change', null);
    },
    scrollToSectionId() {
      if (this.scrollSectionId) this.$scrollToSmoothly(this.scrollSectionId);
    }
  }
};
</script>
